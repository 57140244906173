 @import url('https://fonts.googleapis.com/css?family=Poppins:400,600,800&display=swap');
@font-face 
{
   font-family: 'Gotham Rounded';
   font-style: normal;
   font-weight: normal;
   src: local('Gotham Rounded'), url('../fonts/Gotham-Rounded-Bold.woff') format('woff');
}
:root {
	--background:#111827;
	--forecolor:#949faf;
	--titlecolor:white;
	--highlight:#6adfa7;

	--inputcolor: #353f4f;

	--nav-background:var(--background);
	--nav-color:#eee;

	--footer-background:var(--background);
	--footer-color:white;
	

	--window-background:#444;
	--window-color:#eee;

	--item-background:#1e293b;
	--item-color:#ccc;
	--item-title-color:#eee;

	--btn-background:#0071fb;
	--btn-background-hover:#0061eb;
	--btn-color:white;

	--primary-color:#6adfa7; /*#6fe2ba;*/
	--secondary-color:#6ec2ee; /*#6ebbf9;*/
	--primary-middle-color:#6fdcc5ff;
	--secondary-middle-color:#6ed2d5ff;

	--secondary-text-color:#4ea2ce; /*#6ebbf9;*/
	--dark-color:#202020;
	--gray-color:#aaa;
	--link-hover:#1482b5;
	

	--alert-background:#ff2ad4;
	--alert-color:white;

	--warning-background:#777;
	--warning-background-hover:#ef4040;

}

 *
 {
	box-sizing: border-box;
	scroll-behavior: smooth;
 }
body
{
	font-family:"Poppins",sans-serif;
	font-size:18px;
	margin:0;
	color:var(--forecolor);
	background:var(--background);
} 

a
{
	color:var(--highlight);
	text-decoration:none;
}
a:hover 
{
	text-decoration:underline;
}
.content 
{
	width:100%;
	max-width:1200px;
	margin:0 auto;
	padding:0 40px;
}
.content_inside
{
  amax-width:1200px;
  margin-left:auto;
  margin-right:auto;
  padding:0 70px;
}

.brand_gradient 
{
	position:relative;
	background: linear-gradient(130deg,var(--secondary-color),var(--primary-color));
	padding-bottom:9vw;
	padding-top:9vw;
}
.gradient_top 
{
	width: 100%;
    position: absolute;
    height: 9vw;
    top: -1px;
    left: 0;
}
.gradient_bottom 
{
	width: 100%;
    position: absolute;
    height: 5vw;
    bottom: 0;
    left: 0;
	transform: scale(-1,-1);
}
.gradient_right 
{
	width: 16vw;
    position: absolute;
    height: 16vw;
    top: 5vw;
    right: 0;
}
.gradient_what_why 
{
	width: 100%;
    position: absolute;
    height: 7vw;
    top: -6vw;
    left: 0;
	transform: scale(-1,-1);
}
.flex 
{
	display:flex;
	align-items: center;
}

section 
{
	padding:40px 0;
	min-height:100vh;
	position:relative;
}
section h2
{
	max-width:75%;
}

/* HAMB */
.hamb{
	cursor: pointer;
	padding: 0;
	display:block;
	width:30px;
	height:24px;
	margin-bottom:0 !important;
	margin-right:20px;
  }
  
  .hamb-line {
	background: var(--nav-color);
	display: block;
	height: 3px;
	position: relative;
	width: 30px;
	top:9px;
  } 
  
  .hamb-line::before,
  .hamb-line::after{
	background: var(--nav-color);
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all .2s ease-out;
	width: 100%;
  }
  .hamb-line::before{
	top: 9px;
  }
  .hamb-line::after{
	top: -9px;
  }
  .side-menu:checked ~ #nav_menu
  {
	display:block;
  }
  .side-menu:checked ~ .hamb .hamb-line {
	background: transparent;
  }
  .side-menu:checked ~ .hamb .hamb-line::before {
	transform: rotate(-45deg);
	top:0;
  }
  .side-menu:checked ~ .hamb .hamb-line::after {
	transform: rotate(45deg);
	top:0;
  }

.side-menu
{
	display:none;
}

/* NAVIGATION */
nav 
{
	display:flex;
	flex-direction: column;
	width:100%;
	position:fixed;
	padding:15px 0;
	background:var(--nav-background);
	z-index:4;
}
.nav_menu 
{
	display:none;
	padding: 80px 30px 10px 65px;
    position: absolute;
    width: fit-content;
    top: 0;
    left: 56px;
	box-shadow: 0 4px 12px #00000086;
    background: var(--item-background);
    border-radius: 0px 0px 15px 15px;
	z-index:-1;
}
.nav_menu a 
{
	margin:0 0 10px 0;
	border-bottom:1px solid var(--forecolor);
	display:block;
	color:inherit;
	font-size:0.9em;
	padding:0 0 10px 0;
	text-align:left;
}
.nav_menu a:last-child
{
	border:none;
}
.nav_menu a::after
{
	content:"";
	margin:5px;
}
.nav_menu a:hover 
{
	color:var(--titlecolor);
	text-decoration: none;
}
.nav_brand_link 
{
    display:block;
    line-height: 1;
}
.brand_image 
{
    height:35px;
}
.nav_filter 
{
    width:100%;
    margin:0 50px;
}
.filters_section
{
	display:none;
}
.nav_user 
{
	display:block;
	line-height: 1;
	position:relative;
}
.nav_user_unknown 
{
	width:45px;
	fill:var(--highlight);
	transition:all 0.25s;
}
.nav_user_picture
{
	width:50px;
	height:50px;
	object-fit: cover;
	border-radius:100%;
	border:2px solid var(--highlight);
	position:relative;
	z-index:1;
	transition:all 0.25s;
}
.nav_user_link
{
	display:flex;
	gap:10px;
	align-items: center;
	color:var(--forecolor);
	font-size:0.75em;	
	white-space: nowrap;
}
.nav_user_link:hover 
{
	text-decoration: none;
}
.nav_user_username 
{
	color:var(--highlight);
	margin-bottom:5px;
	transition:all 0.25s;
}
.nav_user_link:hover .nav_user_username 
{
	color:var(--titlecolor);
}
.nav_user_link:hover .nav_user_picture 
{
	border-color:var(--titlecolor);
}
.nav_user_link:hover .nav_user_unknown 
{
	fill:var(--titlecolor);
}
/*.nav_user::after
{
	content:"";
	position:absolute;
	left:-4px;
	top:-4px;
	bottom:-2px;
	right:-4px;
	background-color: var(--titlecolor);
	opacity:0.5;
	z-index: 0;
	border-radius:100%;
}*/
.nav_lang 
{
	display:flex;
}
.btn_lng 
{
	margin:0px;
	border:none !important;
}
.btn_lng:first-child::after 
{
	content:"/";
	margin-left:5px;
	margin-right:5px;
	font-size:0.8em;
}

.disabled, .disabled:hover
{
	color:gray !important;
	pointer-events:none;
}

header
{
	display:flex;
	align-items: center;
	justify-content: center;
	width:100%;
	min-height:100vh;
	overflow: hidden;
	padding:0;
	color:var(--titlecolor);
	transition:opacity 0.5s;
}
.header_hide
{
	opacity:0;
}
.header_text
{
	flex:1;
	display:flex;
	flex-direction: column;
	align-items: center;
	amax-width:75%;
}

.highlight
{
	color:var(--primary-color);
	font-weight:inherit;
}
.header_lost 
{
	flex:1;
	position:relative;
}
.header_lost img 
{
	width:100%;
	padding-left:20px;
}
.lost_background 
{
	position:absolute;
	z-index:-1;
	opacity: 0.75;
}
h1 
{
	font-weight:normal;
	margin:0;
	font-size:2.5em;
	line-height:1.2;
	font-weight:600;
	width:fit-content;
	color:var(--titlecolor);
}
.header_text h1 
{
	text-align: center;
}
h1 small 
{
	display:block;
}
h2
{
	font-weight:normal;
	font-size:1em;
	line-height:1.4;
	margin-top:20px;
	padding-right:50px;
}

.btn_default 
{
	padding:10px 40px;
	color:white;
	text-decoration: none;
	background-color: var(--btn-background);
	border-radius: 25px;
	margin:30px 0 0 0;
	display:block;
	width:fit-content;
	transition: background 0.5s;
}
.btn_disabled 
{
	opacity:0.5;
	pointer-events: none;
}
.btn_default:hover
{
	text-decoration: none;
	background-color: var(--btn-background-hover);
}
.btn_disabled:hover 
{
	background:lightgray;
}

.btn_warning 
{
	background:var(--warning-background);
}
.btn_warning:hover 
{
	background:var(--warning-background-hover);
}

.header_logo 
{
	width:150px;
}

.row
{
	display:flex;
	width:100%;
	gap:40px;
}

.col
{
	padding:20px 0;
	flex:1;
}

#how_works .row 
{
	line-height:1.2;
}
#how_works .col 
{
	text-align:center;
}

.works_image 
{
	width:100%;
	max-width:250px;
	/*height:200px;
	object-fit: contain;*/
	margin:0 auto 20px auto;
	display:block;
}

#why_section
{
	background:#f0f0f0;
	position:relative;
	padding-bottom:9vw;
}

.row_features
{
	margin:70px 0 50px 0;
}
.feature_1 
{
	background:var(--primary-color);
}
.feature_2 
{
	background:var(--primary-middle-color);
}
.feature_3 
{
	background:var(--secondary-middle-color);
}
.feature_4 
{
	background:var(--secondary-color);
}

.row_features .col 
{
	background:white;
	border-radius:10px;
	padding:0;
	box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}
.feature_header 
{
	border-radius:10px 10px 0 0;
	padding:20px 20px;
	font-size:1.25em;
	line-height: 1.2;
	font-weight:600;
	color:white;
}
.feature_body
{
	background:white;
	padding:40px 20px;
	border-radius:0 0 10px 10px;
	font-size:0.9em;
}

/* Plans */
.row_plans 
{
	margin:100px 0 80px 0;
	gap:10px;
	flex-wrap: wrap;
}

.row_plans .col:first-child 
{
	min-width:100%;
}
.row_plans .col:last-child
{
	aflex:2;
}
.row_the_plans 
{
	gap:40px;
}
.plan_col
{
	flex:1;
	padding:0 0 20px 0;
	background:white;
	border-radius: 10px;
	box-shadow: 0 4px 12px #00000026;
}
.plan_title 
{
	border-radius: 10px 10px 0 0;
	color:white;
	font-weight:bold;
	padding:15px 20px;
	font-size:1.25em;
	background:var(--primary-color);
}
.plan_feature 
{
	padding:8px 20px 5px 20px;
	border-bottom:1px solid #ddd;
	font-size:0.8em;
	color:gray;
}
.plan_feature.centered 
{
	text-align:center;
	margin-top:30px;
}
.plan_feature:last-child
{
	border-bottom:none;
}
.plan_feature_value 
{
	font-size:1.25em;
	font-weight: 600;
	color:black;
}

.row_plans .btn_default 
{
	width:100%;
	text-align:center;
}


#faq
{
	position:relative;
	padding-top:100px;
}
.gradient_faq 
{
	width: 100%;
    position: absolute;
    height: 9vw;
    bottom: -9vw;
    left: 0;
	z-index:2;
}
.faq_title 
{
	margin-top:30px;
	margin-bottom:20px;
	acolor:var(--titlecolor);
}
.faq_question 
{
	background:var(--item-background);
	color:var(--titlecolor);
	padding:5px 15px 5px 15px;
	border-radius:25px;
	margin-bottom:20px;
	display:flex;
	gap: 20px;
	align-items:center;
	cursor:pointer;
}
.faq_question_text 
{
	flex:1;
}
.faq_arrow 
{
	width: 15px;
	height: 15px;
	border: 4px solid var(--primary-color);
	border-left: 0;
	border-top: 0;
	transform: rotate(45deg);
	margin-right:5px;
	transition:all 0.25s;
	display:block;
	cursor:pointer;
}
.faq_arrow_extend .faq_arrow
{
	transform: rotate(230deg);
}
.faq_answer
{
	margin-bottom:30px;
	line-height:1.4;
	padding:0 20px;
	display:none;
}
.faq_answer_visible 
{
	display:block;
}
footer 
{
	/*padding:15vw 0 100px 0;*/
	padding:0px 0 30px 0;
	display:flex;
	color:var(--footer-color);
	background:var(--footer-background);
	border-top:1px solid var(--forecolor);
}
.gradient_footer 
{
	width: 100%;
    position: absolute;
    height: 20vw;
    bottom: -20vw;
    left: 0;
}
.share_section
{
	position:relative;
	margin-bottom:30px;
	display:flex;
	gap:10px;
}
.share_section a 
{
	display:block;
	line-height:1;
}
.share_section svg
{
	display:block;
	width:45px;
	height:45px;
	fill:white;
}

footer a 
{
	color:white;
	text-decoration: underline;
}
footer .row
{
	align-items: center;
	justify-content: center;
}
.footer_text 
{
	amax-width:50%;
	margin-top:20px;
	line-height:1.2;
}
.footer_link
{
	font-size:0.9em;
	color:white;
	display:block;
	text-decoration: none;
	padding-bottom:2px;
	border-bottom:1px solid white;
	width:fit-content;
	margin:15px 0;
}
#how_works svg 
{
	display:block;
	width:90px;
	height:90px;
	padding:20px;
	border-radius:50%;
	margin:0 auto;
	fill:white;
	background:#0088c8;
	margin-bottom:20px;
}

.policy 
{
	font-size:0.9em;
	padding:100px 0 150px 0;
}
.policy h2 
{
	margin-top:50px;
	font-size:1.5em;
	font-weight: 600;
}
.policy h3 
{
	font-weight:600;
	margin-bottom:10px;
	margin-top:30px;
}
.text_gradient
{
	background: linear-gradient(95deg,var(--secondary-color),var(--primary-color));
	background-size: 100%;
	background-clip: text;
	background-position: 0 0;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.hide, .hidden
{
	 display:none !important;
}
.ashow
{
	display:block !important;
}
.lng
{
	display:none;
}
.lng_en
{
	display:inherit;
}

.mobile 
{
	display:none;
}
.no_mobile 
{
	display:block;
}

/* ---- Upload image generic ---- */
.select_picture
{
	width:100%;
	display:block;
	padding-top:100%;
	position:relative;
	background: var(--inputcolor);
	margin-bottom:20px;
}
.select_picture_content
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	display:flex;
	align-items: center;
	justify-content: center;
}
.select_picture img
{
	width:100%;
	height:100%;
	position:absolute;
	left:0;
	top:0;
	object-fit: cover;
	object-position: center center;
}
.select_picture_default
{
	width:50%;
	fill:var(--forecolor);
	transition:all 0.5s;
}
.select_picture:hover .select_picture_default
{
	fill:var(--titlecolor);
}

/* ---- Upload form ---- */

#upload_nft 
{
	padding-top:80px;
}
.upload_link
{
	text-decoration: underline;
	font-weight:600;
	color:var(--primary-color);
}

.upload_form
{
	background:#f3f3f3;
	padding:50px 70px;
	box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
	border-radius:10px;
	border:2px solid var(--secondary-color);
}

.account_section .upload_form
{
	border:none;
	box-shadow: none;
	padding:0;
	background:transparent;
}

.upload_row 
{
	display:flex;
	gap:60px;
}
.upload_col_1
{
	flex:1;
}
.upload_col_2
{
	flex:3;
	padding-top:30px;
}
label
{
	display:block;
	font-size:0.8em;
	margin-bottom:10px;
}
input, textarea, select
{
	border:none;
	background:var(--inputcolor);
	color:var(--titlecolor);
	font-family:"Poppins", sans-serif;
	font-size:0.9em;
	padding:5px 10px;
	outline:none;
	margin-bottom:10px;
	width:100%;
}
textarea 
{
	resize:vertical;
}
::-webkit-calendar-picker-indicator {
	filter: invert(1);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
{
  -webkit-text-fill-color: var(--titlecolor);
  -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s;
}
fieldset
{
	border:none;
	margin-bottom:20px;
	padding:0;
}

.input_row
{
	display: flex;
	align-items: baseline;
}
.input_row input 
{
	margin-right:10px;
}
.input_type 
{
	font-size:1.2em;
	color:gray;
}

.upload_thumb_image
{
	width:100%;
	display:block;
	padding-top:100%;
	position:relative;
	background: var(--inputcolor);
	margin-bottom:20px;
}
.upload_thumb_content
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	display:flex;
	align-items: center;
	justify-content: center;
}
.upload_thumb_image img
{
	width:100%;
	height:100%;
	position:absolute;
	left:0;
	top:0;
	object-fit: cover;
	object-position: center center;
}
.upload_select_icon 
{
	width:60px;
	fill:var(--forecolor);
	transition:all 0.5s;
}
.upload_thumb_image:hover .upload_select_icon
{
	width:70px;
	fill:var(--titlecolor);
}

.upload_btn 
{
	margin-top:5px;
	display:block;
	padding:5px 10px;
	color:white;
	font-size:0.9em;
	background:gray;
	width:fit-content;
	border-radius:5px;
	transition:background 0.5s;
}
.upload_btn:hover 
{
	background:lightslategray;
}
.upload_files_selected
{
	margin-bottom:15px;
	margin-top:10px;
}
#upload_files
{
	font-size:0.9em;
	min-height:48px;
    border-radius: 0px;
	background:var(--inputcolor);
	max-height:180px;
	overflow-y: auto;
	word-break: break-all;
	margin-bottom:10px;
	cursor:pointer;
}

.upload_files_resume
{
	font-size:0.8em;
	afont-weight:600;
	color:var(--titlecolor);
}
.upload_files_resume_row
{
	align-items: center;
}
.upload_files_resume_col 
{
	flex:1;
}
.filelist_row 
{
	display:flex;
	align-items: center;
	border-bottom:1px solid #d3d3d3;
	padding:7px;
}
.filelist_name
{
	flex:1;
	font-size: 0.9em;
	padding-left:5px;
}
.filelist_size 
{
	font-size:0.8em;
	padding-right:10px;
}
.filelist_row:last-child
{
	border:none;
}

.upload_action
{
	display: flex;
	flex-direction:column;
    align-items: end;
}
.upload_error
{
	margin-top:10px;
	margin-bottom:20px;
	font-size:0.9em;
	padding:5px 10px;
	color:white;
	width:100%;
	background:#d04040;
	border-radius:5px;
}
.upload_success
{
	padding:20px 15px;
	color:black;
	line-height:1.4;
	width:100%;
	word-break: break-word;
}
.upload_success_title 
{
	
}

.upload_form .btn_default 
{
	margin-top:0px !important;
}

.nft_checkout_link
{
	font-weight:600;
	display:inline-block;
	word-break: break-all;
	color:var(--secondary-color);
}

.nft_checkout_section 
{
	display:flex;
	align-items: center;
	gap:20px;
	margin: 20px 0 30px;
}
.nft_checkout_tips 
{
	margin-bottom:40px;
}
.tooltip 
{
	position:relative;
	line-height:1;
	fill:gray;
}
.tooltiptext
{
	background-color: #999;
	color: white;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	width:max-content;
	bottom: -100%;
	left: 0;
	font-size:0.65em;
	opacity: 0;
	transition: opacity 0.3s;
	pointer-events: none;
}

.tooltip:hover .tooltiptext 
{
	opacity:1;
}

  .upload_row_fields 
  {
	gap:1;
	margin:0;
  }
  .upload_field 
  {
		position:relative;  
		height:58px;
		padding:5px 10px;
		display:flex;
		align-items: center;
		margin-bottom:10px;
  }
  .upload_field input,
  .upload_field textarea
  {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	padding:0 10px;
	border:0;
	border:none;
	background:var(--inputcolor);
	color:var(--titlecolor);
	aborder-bottom:1px solid lightgray;
	resize:none;
  }
  .upload_field_textarea
  {
	height:120px;
  }
  .upload_field label
  {
	position:absolute;
	top:17px;
	left:0;
	padding-left:10px;
	z-index: 2;
	pointer-events: none;
	font-size:0.85em;
	transition:all 0.25s;
	background:transparent;
	width:100%;
	color:var(--forecolor);
  } 

  .upload_field input:focus, .upload_field input:not(:placeholder-shown),
  .upload_field textarea:focus, .upload_field textarea:not(:placeholder-shown)
  {
	padding:20px 10px 5px 10px;
  }
  .upload_field input:focus + label, .upload_field input:not(:placeholder-shown) + label,
  .upload_field textarea:focus + label, .upload_field textarea:not(:placeholder-shown) + label
  {
	font-size:0.75em;
	padding-top:3px;
	top:0;
	acolor:#777;
  }


.upload_field input::-webkit-input-placeholder,
.upload_field textarea::-webkit-input-placeholder
{
  opacity:0;
  transition: inherit;
}
.row_fields 
{
	display:flex;
}
.col_fields
{
	flex:1;	
}
.col_fields .upload_field 
{
	border-right:1px solid lightgray;
}
.col_fields:last-child .upload_field
{
	border-right:none;
}
.progress
{
    gap: 10px;
	font-size:0.9em;
	position:relative;
}
.progress_bar_wrapper 
{
	width: 100%;
    position: relative;
    height: 30px;
    border-radius: 20px;
    background: lightgray;
	overflow: hidden;
}
.progress_bar 
{
	position: absolute;
    left: 0;
    top: 0;
    border-radius: 20px;
    height: 100%;
    width: 0;
    background: var(--primary-color);
}
.progress_value 
{
	position: absolute;
    left: 10px;
	top:2px;
    color: white;
}

/* ---- Cookies ---- */
.cookies 
{
	font-size:0.8em;
	line-height: 1.2;
	position:absolute;
	right:0;
	top:0;
	padding:5px 10px 10px 10px;
	background:var(--primary-color);
	color:white;
	width:150px;
	border-radius:10px 10px 10px 0;
	text-align:left;
}
.btn_cookies 
{
	margin-top:10px;
	display:block;
	text-align:right;
}

/* ---- Login ----*/
.login_section 
{
	max-width:400px;
	margin:30px auto;
	background: var(--item-background);
	color:var(--forecolor);
    border-radius: 10px;
    padding:20px 30px 30px 30px;
	text-align: center;
	font-size:17px;
	box-shadow: 0 4px 12px rgb(0 0 0 / 55%);
}

.login_section input
{
	max-height: 44px;
    width: 100%;
    box-sizing: border-box;
    background: var(--inputcolor);;
    border:none;
	color:var(--titlecolor);
    margin-bottom: 40px;
    padding: 12px;
    font-size: 0.9em;
}

#login_email
{
	text-align: center;
}

.login_title
{
	font-size:1.3em;
	font-weight:600;
	max-width:100%;
	color:var(--titlecolor);
}
.login_info
{
	margin-top:1.2em;
	font-size:0.9em;
	margin-bottom:30px;
	line-height:1.3;
	text-align:left;
}
.login_error 
{
	font-size:0.8em;
	margin-bottom:30px;
    padding: 5px 15px;
}
.login_close_window 
{
	text-align:left;
	margin-top:40px;
}
.login_section .btn_default
{
	margin-top:40px;
	width:100%;
}

/* ---- NFTs GRID ----*/
.grid
{
	display:flex;
	flex-wrap: wrap;
	padding:0;
	margin:0 -10px 40px -10px;
}
.grid_empty 
{
	text-align: center;
    font-size: 1.5em;
	height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	color:var(--primary-color);
}
.grid_2_cols .nft_item
{
	min-width:50%;
	max-width:50%;
	width:50%;
}
.grid_3_cols .nft_item
{
	min-width:33.33%;
	max-width:33.33%;
	width:33.33%;
}
.grid_4_cols .nft_item
{
	min-width:25%;
	max-width:25%;
	width:25%;
}
.nft_item
{
	padding: 0 10px 40px 10px;
	flex:1;
	text-decoration:none;
}
.nft_item:hover .nft_thumbnail
{
	transform: scale(1.2);
	transition:all 0.5s;
}
.nft_content
{
	width:100%;
	padding-top:100%;
	position:relative;
	overflow: hidden;
	border-radius:10px 10px 0 0;
}

.nft_thumbnail
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	object-fit: cover;
	transition:all 0.5s;
}
.nft_stats 
{
	display:flex;
	justify-content: flex-end;
	padding:5px 10px;
	background:#f0f0f0;
	border-radius:0 0 10px 10px;
}
.nft_stats_item
{
	display:flex;
	font-size:0.7em;
	align-items:center;
	color:gray;
	margin-left:10px;
	padding-right:10px;
	border-right:1px dotted gray;
}
.nft_stats_item:last-child
{
	border-right:none;
	padding-right:0;
}
.nft_stats svg 
{
	height:18px;
	fill:gray;
	margin-left:5px;
}
.nft_info 
{
	background:white;
	padding:15px 15px;
	color:black;
	display:flex;
	flex-direction:column;
	border-radius:10px;
	text-decoration:none;
	box-shadow: 0 1px 1px #00000008, 0 3px 6px #00000005;
	position:relative;
}

.nft_price
{
	display:flex;
	align-items: center;
	margin-top:15px;
	padding-top:10px;
	border-top:1px solid lightgray;
}
.nft_title
{
	flex:1;
	font-size:1.25em;
	line-height:1.2;
	font-weight:600;
	margin:15px 0 5px 0;
}
.nft_description
{
	font-size:0.8em;
	color:#333;
}
.nft_amount_sep
{
	margin:0 10px;
	color:gray;
}
.nft_amount 
{
	display:flex;
	align-items:baseline;
	color:black; /*var(--primary-color);*/
	font-weight:800;
	font-size:1.75em;
}
.nft_amount .currency
{
	margin-left:3px;
	aheight:12px;
	fill:black; 
	font-weight:600;
	line-height: 1.2;
	font-size:.75em;
}

/*---- Checkbox ----*/
.checkbox {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 8px;
	cursor: pointer;
	color:var(--titlecolor);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  .checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  
  .checkmark {
	position: absolute;
	top: 3px;
	left: 0;
	height: 15px;
	width: 15px;
	background-color: transparent;
	border:1px solid var(--forecolor);
  }

  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  .checkbox input:checked ~ .checkmark:after {
	display: block;
  }
  
  .checkbox .checkmark:after 
  {
	width: 4px;
    height: 8px;
    border: 1px solid var(--highlight);
    border-width: 0 2px 2px 0;
    top: 0px;
    left: 4px;
    transform: rotate(45deg);
  }


/* ---- Account ----*/

.nft_popup_hide 
{
	right:-350px !important;
}
.nft_popup
{
	position:fixed;
	right:0;
	top:0;
	z-index:5;
	min-height:100%;
	background:#f0f0f0;
	width:300px;
	box-shadow: -7px 0px 25px 0px rgba(0,0,0,0.28);
	transition:all 0.5s;
	display:flex;
	flex-direction: column;
}
.nft_popup_top
{
	color:white;
	background:var(--primary-color);
	margin-bottom:0px;
}
.nft_popup_bar 
{
	display:flex;
	align-items: center;
	padding:10px 10px 10px 10px;
}
.nft_popup_bar a 
{
	line-height:1;
	color:white;
	fill:white;
}
.nft_popup_bar svg 
{
	width:18px;
	height:18px;
}
.nft_popup_body 
{
	padding:20px 20px;
	flex:1;
}
.nft_popup_bottom 
{
	display:flex;
	padding:20px;
}
.nft_popup_bottom a 
{
	flex:1;
	text-align: center;
	border-radius:0;
	padding:20px 10px !important;
}
.nft_popup_image_wrapper
{
	position: relative;
    padding-top: 50%;
}
.nft_edit_title 
{
	font-weight:400;
	font-size:1em;
	margin-right:10px;
	flex:1;
}
.nft_edit_link
{
	word-break: break-word;
    font-size: 0.75em;
    color: var(--secondary-text-color);
    line-height: 1.2;
    display: block;
    margin-bottom: 5px;
}
.nft_edit_links
{
	margin-bottom:30px;
}
.nft_popup_image 
{
	position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.nft_popup input, .nft_popup select 
{
	afont-size:0.75em;
}

.account_panel 
{
	display:flex;
}
.account_content 
{
	width:100%;
	height:100%;
}
.account_page footer 
{
	margin-top:50px;
	apadding:20px 0;
}
.account_page .footer_text
{
	margin:0;
}
.account_page .footer_text_info 
{
	display:none;
}
.account_menu
{
	font-size:0.85em;
	flex-direction: column;
	display:flex;
	font-weight:600;
	width:220px;
    padding: 10px 50px 10px 0px;
    height: fit-content;
    border-radius: 20px;
	position:sticky;
	top:100px;
}
.account_menu_column
{

}
.account_link 
{
    padding: 20px 0;
    margin-left: 0;
	margin-right:0px;
	color:inherit;
	border-bottom:1px solid var(--forecolor);
	fill:var(--forecolor);
	display:flex;
	align-items: center;
}
.account_link:last-child
{
	border:none;
}
.account_link svg 
{
	width:24px;
	height:24px;
	margin-right:10px;
}

.account_link:hover 
{
	fill:var(--titlecolor);
	color:var(--titlecolor);
} 

.account_link.active 
{
	fill:var(--highlight);
	color:var(--highlight);
}
.account_section
{
	padding-top:100px;
}
.account_section_title 
{
	display:none;
}
.account_panel_right 
{
	background:var(--item-background);
	border-radius:10px;
	box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.3);
	padding:30px 40px 40px 40px;
	width:100%;

}
.account_title 
{
	margin-bottom:50px;
	font-size:1.5em;
	background:var(--primary-color);
	color:white;
	padding:10px 20px;
	font-weight:600;
	border-radius:10px;
}

.account_row 
{
	display:flex;
	gap:50px;
}
.account_row.reverse 
{
	flex-direction: row-reverse;
}
.account_col_content
{
	flex:2;
}
.account_col_content_ext
{
	flex:3;
}
.account_col_side
{
	flex:1;
}

#settings label 
{
	margin-bottom:5px;
	font-size:.8em;
	color:var(--titlecolor);
}
#settings label small
{
	color:var(--forecolor);
	display:block;
	font-size:0.95em;
}
#settings .label_extra
{
	font-size:0.8em;
}
#settings legend
{
	margin-bottom:20px;
	font-size:0.8em;
}
#settings legend b, #settings label b
{
	display:block;
	font-weight:600;
	color:var(--titlecolor);
}
#settings fieldset 
{
	amargin-bottom:30px;
}
#settings input, #settings textarea, #settings select
{
	margin-bottom:15px;
	padding:10px;
	background:var(--inputcolor);
	color:var(--titlecolor);
	border:none;
}
#settings input:disabled
{
	background:transparent;
	color:var(--forecolor);
	padding:0;
	margin-top:-5px;
}


#account_page .btn_default 
{
	margin-top:10px;
	font-size:0.9em;
	padding:8px 15px;
}
.account_stats 
{
	awidth:fit-content;
	display:flex;
	flex-direction: column;
	gap:20px;
	margin:0 auto 0px auto;
}
.account_stats_item 
{
	padding:0 0 10px 0;
}

.account_stats_title
{
    /*color: var(--gray-color);*/
	background: var(--primary-color);
	padding:2px 10px;
	color:white;
    flex: 1;
	font-size:0.8em;
	margin-bottom:0;
}
.account_stats_data
{
	padding:15px 20px 15px 10px;
	background:white;
	font-size: 1.5em;
    font-weight: 600;
	line-height: 1;
	/*color:var(--primary-color);*/
}
.account_stats_data .small
{
	font-size: 0.75em;
	font-weight:400;
}
.account_stats_data .smaller
{
	line-height:1.3;
	margin-top:10px;
	font-size: 0.6em;
	font-weight:400;
	color:gray;
}
.account_stats_plan 
{
	font-size:0.5em;
	line-height: 1.2;
	font-weight: 400;
	margin:10px 0;
}

.account_actions_row 
{
	display:flex;
}
.account_actions_result
{
	flex:1;
}

.transactions_info 
{
	margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid gray;
	align-items: center;
}
.transactions_info h1 
{
	font-size:2.5em;
	font-weight:600;
	display:flex;
	align-items: baseline;
}
.transactions_info h1 small
{
	font-size:0.5em;
	font-weight:normal;
}

.transactions_info .col 
{
	padding:0;
}

.transactions_info .next_date
{
	font-size:0.9em;
}
.transactions_warning
{
	display: block;
    margin-top: 10px;
    background: #ef4040;
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
}
.pay_day
{
	font-weight: 600;
}
.transactions_parameters
{
	align-items: center;
}
.transactions_parameters .col
{
	padding:0;
}
.transactions_parameters .col:last-child
{
	text-align: right;
}
.transactions_parameters .btn_default
{
	display:inline-block;
}
.transactions_total 
{
	display: flex;
    gap: 10px;
    align-items: center;
    padding-right: 15px;
    justify-content: end;
	font-size:0.9em;
	font-weight:600;
}
#transactions_table
{
	width:100%;
	font-size:0.85em;
	border-spacing: 1px;
	border-collapse: collapse;
	text-align:left;
	margin:20px 0 20px 0;
	min-height:200px;
}
.tr 
{
	display:table-row;
}
.td 
{
	display:table-cell;
	padding:5px 10px;
	height:auto;
	border-bottom:1px solid var(--item-background);
}
.td.center, th.center 
{
	text-align:center;
}
.td.right, th.right 
{
	text-align:right;
}
th.auto 
{
	width:1px;
}
.tr:last-child .td 
{
	border:none;
}

.tr:nth-child(odd)
{
	abackground:#eee;
}

th
{
	background:var(--background);
	color:var(--forecolor);
	border-right:2px solid var(--item-background);
	font-weight: 400;
	padding:5px 15px;
}
/*
th:first-child, td:first-child
{
	width:auto;
}*/
td
{
	padding:5px 10px;
}
tr 
{
	aborder-bottom: 1px solid #ddd;
}
td span 
{
	display:block;
}
.transactions_empty
{
	text-align: center;
	font-size:1.25em;
	color:gray;
}
/*
tr:nth-child(even) 
{
	background-color: #f4f4f4;
}*/

/* ---- RESPONSIVE -----------------*/

@media (max-width: 768px) 
{
	body 
	{
		font-size:18px;
	}
	.no_mobile 
	{
		display:none;
	}
	.mobile 
	{
		display:block;
	}
	
	
	nav .flex 
	{
		align-items: center;
		justify-content: start;
	} 
	.nav_brand
	{
		flex:1;
		padding-left:10px;
	}
	.nav_lang 
	{
		margin-right:20px;
	}

	
	  
	h1
	{
		line-height: 1.1;
	}
	section h2 
	{
		max-width:100%;
		padding-right:0;
	}
	h3 
	{
		margin-bottom:20px;
	}
	.content, .content_inside 
	{
		padding:0 20px;
	}

	.feature_header 
	{	
		font-size:1.25em;
	}

	.row, .row_fields
	{
		flex-direction:column;
	}

	.upload_row 
	{
		gap:0;
		flex-direction:column;
	}
	.upload_files_resume_row 
	{
		gap:10px;
		align-items: start;	
	}
	.upload_error 
	{
		margin-top:30px;
	}
	.upload_form 
	{
		padding: 40px 15px 40px 15px;
	}
	.upload_form .btn_default 
	{	
		width:100%;	
		text-align:center;
	}
	header 
	{
		padding-top:80px;
	}
	header .flex
	{
		flex-direction:column-reverse;
	}
	.header_text 
	{
		padding-top:40px;
		padding-bottom:40px;
	}
	footer 
	{
		padding-bottom:50px;
	}
	.footer_text 
	{
		max-width:100%;
	}
	#how_works .row 
	{
		padding-bottom: 40px;
 	   	gap: 0;
	}

	#faq {
		padding-top: 20vw;
	}
	.brand_gradient {
		padding-top: 25vw;
	}

	/* Plans */
	.row_plans 
	{
		gap:0;
	}

	.row_the_plans 
	{
		gap:50px;
	}

	/* Account */

	.nft_popup 
	{
		min-height: auto;
		right:20px;
		left:20px;
	 	bottom:0;
		width:calc(100% - 40px);
		top:auto;
		box-shadow: 0px -7px 25px 0px rgba(0,0,0,0.28);
	}
	.nft_popup_hide 
	{
		bottom:-100% !important;
	}
	.nft_edit_link
	{
		margin-bottom:10px;
	}
	body.account_page 
	{
		background:#f3f3f3;
	}
	.account_content 
	{
		height:100%;
	}
	.account_section 
	{
		padding:70px 0 0 0;
	}
	.account_section_title 
	{
		display:block;
		margin-bottom:20px;
	}
	.account_menu 
	{
		width:100%;
		padding:0;
		flex-direction:row;
		flex-wrap: wrap;
		position:fixed;
		left:0;
		top:55px;
		background:white;
		border-radius:0;
		gap:5px;
		border-bottom:1px solid gray;
		border-top:1px solid gray;
		z-index:3;
	}
	.account_link 
	{
		flex-direction:column;
		flex: 1;
    	font-size: 0.7em;
    	flex-direction: column;
		padding: 15px 0;
		border:none;
		gap:4px;
		line-height: 1;
	}
	.account_link.active {
		fill: white;
		color: white;
		background: var(--secondary-color);
	}
	.account_link svg {
		margin-right: 0;
		width:30px;
		height:30px;
	}
	.account_link_text 
	{
		display:none;
	}
	.account_panel 
	{
		flex-direction:column;
	}
	.account_panel_right {
		padding: 0;
		margin-top:70px;
	}

	.grid 
	{
		flex-direction:column;
	}
	.nft_item
	{
		min-width:100% !important;
	}
	.account_stats
	{
		flex-direction:column;
	}
	.account_stats_data 
	{
		font-size:1.5em;
	}
	.account_stats_item 
	{
		flex-direction:row;
	}
	.account_row 
	{
		flex-direction: column;
	}
	.account_row.reverse 
	{
		flex-direction: column-reverse;
	}
	.transactions_parameters
	{
		gap:0;
		margin-bottom:30px;
	}
	.transactions_parameters .col 
	{
		padding:10px 0;
		width:100%;
	}

	.transactions_parameters input
	{
		width: 100%;
		box-sizing: border-box;
		background: #fff;
		border: 1px solid #32325d1a;
		border-radius: 6px;
		margin-bottom: 12px;
		padding: 12px;
		font-size: 1em;
	}
	.transactions_info
	{
		align-items: start;
    	margin: 30px 0;
    	padding-bottom: 40px;
    	gap: 20px;
	}
	.transactions_info h1 
	{
		font-size:4em;
		padding-top:10px;
	}

	fieldset 
	{
		padding:0;
	}
	#nft_uploading
	{
		margin-top:30px;
		gap:0;
	}
	.progress_bar_wrapper
	{
		height:40px;
	}
	.progress_value
	{
		top:8px;
		left:15px;
	}
}